import React from "react"
import Anchor from "../../core/components/anchor"
import Block from "../../core/components/block"
import Button from "../../client/compositions/button"
import Row from "../../core/components/row"

/** NeedHelp component */
function NeedHelpComponent({ children, buttons }) {
  return (
    <Row id="need-help" className="need-help">
      <Block className="block-media">{children}</Block>
      <Block className="block-content">
        <h3 className="title">Need help?</h3>
        <div className="content">
          <p>
            You have a project to discuss, or need some additional resources?
            Our team is available to answer all your questions.
          </p>
          <Button to="contact" image="icons/arrow-right.svg">
            Get in touch
          </Button>
        </div>
      </Block>
    </Row>
  )
}

/**
 * Compose
 */
const NeedHelp = NeedHelpComponent

export default NeedHelp
