// @flow

// react
import * as React from "react"
import classNames from "react-css-module-classnames"

// components
import Anchor from "./anchor"

// <PagenatedList />
type Props = {
  /** Title */
  title?: String,
  /** Items fetcher */
  fetcher?: () => Promise,
  /** Number of items per page */
  itemsPerPage: number,
  /** Total number of items */
  totalItems?: number,
  /** Data fetcher */
  mode: "load-more", // @todo pages, infinite-scroll
  /** Content */
  children: any,
  /** Custom class for root element */
  className?: string,
  ...
}

type State = {
  isLoading: boolean,
  items: Array<any>,
  page: number,
}

/**
 * PagenatedList
 *
 * ## CSS Classes
 * |------------------|--------------------------------------------------------|
 * | class            | Purpose                                                |
 * |------------------|--------------------------------------------------------|
 * | .pagenated-list  | Root element                                           |
 * | .menu-items      | List of menu items                                     |
 * | .menu-item       | Single menu item                                       |
 * |------------------|--------------------------------------------------------|
 */
class PagenatedList extends React.Component<Props> {
  static defaultProps = {
    itemsPerPage: 3,
    totalItems: null,
    mode: "load-more",
    loadMoreButton: null,
  }

  state = {
    isLoading: false,
    items: [],
    page: 1,
    totalPages: null,
  }

  loadMore() {
    const { fetcher, itemsPerPage } = this.props
    const { items, page } = this.state

    let nextPage = page + 1

    if (typeof fetcher === "function") {
      this.setState({ isLoading: true })

      fetcher(nextPage).then((newItems) => {
        // stop loading pages if no more items exist
        if (!newItems) {
          this.setState({ isLoading: false, totalPages: page })
          return
        }

        // add loaded items to list
        this.setState(
          Object.assign(
            // update list
            {
              isLoading: false,
              items: [].concat(items, newItems || []),
              page: nextPage,
            },
            // auto detect last page
            newItems.length < itemsPerPage ? { totalPages: nextPage } : {}
          )
        )
      })
    }
  }

  // react methods

  componentDidMount() {
    const { children } = this.props
    const items = React.Children.toArray(children) || []
    this.setState({ items })
  }

  render() {
    let {
      title,
      fetcher,
      itemsPerPage,
      totalItems,
      mode,
      loadMoreButton,
      children,
      className,
      ...navProps
    } = this.props
    let { isLoading, items, page, totalPages } = this.state

    let pageStart = 0
    let pageLength = itemsPerPage

    if (mode === "load-more") {
      pageLength = itemsPerPage * page
    }

    return (
      <nav
        {...navProps}
        {...classNames("pagenated-list")
          .plus(className)
          .plus(isLoading ? "is-loading" : "")}
      >
        {title && <h2 {...classNames("title")}>{title}</h2>}
        <ul {...classNames("menu-items")}>
          {(items || [])
            // only show items on current page
            .slice(pageStart, pageStart + pageLength)
            .map((child, i) => (
              <li key={i} {...classNames("menu-item").plus("loaded")}>
                {child}
              </li>
            ))}
        </ul>
        {mode === "load-more" &&
          (totalPages == null || page < totalPages) &&
          (totalItems === null || items.length < totalItems) &&
          (loadMoreButton ? (
            React.cloneElement(loadMoreButton, {
              onClick: this.loadMore.bind(this),
              className: `button`,
            })
          ) : (
            <button
              onClick={this.loadMore.bind(this)}
              {...classNames("button")}
            >
              Load More
            </button>
          ))}
      </nav>
    )
  }
}

/**
 * Exports
 */
export default PagenatedList
