// libraries
import React from "react"
import Anchor from "../../core/components/anchor"
import Banner from "../../client/compositions/banner"
import Button from "../../client/compositions/button"
import Block from "../../core/components/block"
import DecorationFluid from "../../client/compositions/decoration-fluid"
import Navigation from "../../core/components/navigation"
import NeedHelp from "../../client/compositions/need-help"
import PagenatedList from "../../core/components/pagenated-list"
import Row from "../../core/components/row"
import Tagline from "../../client/compositions/tagline"
import { quickMenu } from "../../client/elements/menus"

import resources from "../../client/data/resources.json"
import videos from "../../client/data/videos.json"

/**
 * Resources Page
 * @see https://zpl.io/VKRN9oP
 */
function ResourcesPage() {
  const ITEMS_PER_PAGE = 3

  const toVideoButton = ({ name, url, image }) => (
    <Button key={url} url={url} image={image}>
      {name}
    </Button>
  )

  return (
    <article id="page-resources" className="page-content light-quick-menu">
      {quickMenu}

      <Banner
        title={
          <>
            Design
            <br /> Resources
          </>
        }
        content={
          <>
            <p>
              Invinity aspires to create close collaborations with architects,
              and interior designers. Below you can find useful resources such
              as 2D and 3D models of our different lines for your free use
              within your drawings.
            </p>
            <Button
              image="icons/arrow-right.svg"
              to="/static/docs/catalogue.pdf"
            >
              Download Catalogue
            </Button>
          </>
        }
        className="flipped contact-banner"
      >
        <DecorationFluid image="resources/banner.jpg" />
      </Banner>

      <Row id="downloads">
        <Block className="block-content">
          <dl className="resource-items">
            {resources.map(({ name, image, downloads }) => (
              <div className="resource-item">
                <dt className="title">{name}</dt>
                <dd className="image">
                  <DecorationFluid image={image} />
                </dd>
                <dd className="content">
                  <Navigation>
                    {downloads.map(({ name, size, url }) => (
                      <Button to={url} image="icons/download.svg">
                        {name}
                      </Button>
                    ))}
                  </Navigation>
                </dd>
              </div>
            ))}
          </dl>
        </Block>
      </Row>

      <NeedHelp>
        <DecorationFluid image="/resources/need-help.small.jpg" />
      </NeedHelp>

      <Row id="videos">
        <Block className="block-heading">
          <h3 className="title">
            Video
            <br /> Materials
          </h3>
          <Button image="icons/arrow-right.svg">Go to channel</Button>
        </Block>
        <Block className="block-content">
          <PagenatedList
            itemsPerPage={ITEMS_PER_PAGE}
            totalItems={videos.length}
            fetcher={async (page) => {
              const start = (page - 1) * ITEMS_PER_PAGE
              const end = start + ITEMS_PER_PAGE

              return videos.slice(start, end).map(toVideoButton)
            }}
            loadMoreButton={
              <Button image="icons/load-more.svg">Load more</Button>
            }
          >
            {videos.slice(0, 3).map(toVideoButton)}
          </PagenatedList>
        </Block>
      </Row>
      <Tagline />
    </article>
  )
}

export default ResourcesPage
